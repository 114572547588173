import firstPostDish from '../assets/gallery/images/blog_post_home_page/1_post_dish.png'
import secondPostDish from '../assets/gallery/images/blog_post_home_page/2_post_dish.png'
import thirdPostDish from '../assets/gallery/images/blog_post_home_page/3_post_dish.png'
import fourthPostDish from '../assets/gallery/images/blog_post_home_page/4_post_dish.png.png'

const foodBlogData = [
    {
        image: firstPostDish,
        title: "10 Reasons To Do A Digital Detox Challenge",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: secondPostDish,
        title: "Traditional Soft Pretzels with Sweet Beer Cheese",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: thirdPostDish,
        title: "The Ultimate Hangover Burger: Egg in a Hole Burger",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: fourthPostDish,
        title: "My Favorite Easy Black Pizza Toast Recipe",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: thirdPostDish,
        title: "The Ultimate Hangover Burger: Egg in a Hole Burger",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: fourthPostDish,
        title: "My Favorite Easy Black Pizza Toast Recipe",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: firstPostDish,
        title: "10 Reasons To Do A Digital Detox Challenge",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: firstPostDish,
        title: "10 Reasons To Do A Digital Detox Challenge",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: secondPostDish,
        title: "Traditional Soft Pretzels with Sweet Beer Cheese",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: thirdPostDish,
        title: "The Ultimate Hangover Burger: Egg in a Hole Burger",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: secondPostDish,
        title: "Traditional Soft Pretzels with Sweet Beer Cheese",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },
    {
        image: thirdPostDish,
        title: "The Ultimate Hangover Burger: Egg in a Hole Burger",
        description: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat",
        linkTo: ""
    },

]

export default foodBlogData